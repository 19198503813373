import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import GoogleLogin from "react-google-login";
import * as Sentry from "@sentry/react";
import { trace } from "firebase/performance";

import "./google-ads-button.css";
import Spinner from "./spinner";

const GoogleAdsButton = (props) => {
  const { perf } = props;
  const adsLinkingTrace = trace(perf, "HOME_GOOGLE_ADS_LINKING_TRACE");

  const history = useHistory();

  const [isLoading, setLoading] = useState(false);
  const [isLinked, setLinked] = useState(false);
  const [auditId, setAuditId] = useState("");
  const [error, setError] = useState(null);

  if (isLinked && auditId != "" && auditId != undefined && auditId != null) {
    // history.push(`/audit-results?uid=${auditId}`);
    window.location.href = `${window.origin}/audit-results?uid=${auditId}`;
  }

  const onFailure = (response) => {
    console.log(response);
    Sentry.captureException(response);
  };
  const onSuccess = async (response) => {
    try {
      adsLinkingTrace.start();
      Sentry.captureMessage(
        "Successfully retrieved ServerAuthCode: " + response.code
      );
      console.log(response.code);
      setLoading(true);
      const connectResponse = await fetch(
        encodeURI(
          `https://googleadslinking.campaignhero.app/api/Linking/GoogleAdsLinking`
        ),
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            servertoken: response.code,
            email: "",
            accessToken: "",
            code: "",
            redirect_uri: window.location.origin
          })
        }
      );
      const connectResponseData = await connectResponse.json();
      console.log(connectResponseData);
      Sentry.captureMessage(
        "Successfully returned Linking Response: " +
          connectResponseData.CustomerUID
      );
      adsLinkingTrace.stop();
      setLoading(false);
      setLinked(true);
      setAuditId(connectResponseData.CustomerUID);
    } catch (err) {
      adsLinkingTrace.stop();
      setError("There was an error during linking! Please try again later");
      Sentry.captureException(err);
      setAuditId("");
      setLinked(false);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="primary-button-container">
      {isLoading && <Spinner />}
      {!isLoading && (
        <GoogleLogin
          className="primary-button-button mediumLabel button"
          clientId="555790073484-p03escl4dloejcva9jri2hpdk4k11488.apps.googleusercontent.com"
          scope="https://www.googleapis.com/auth/adwords"
          responseType="code"
          disabled={false}
          accessType="offline"
          onSuccess={onSuccess}
          onFailure={onFailure}
          buttonText={props.buttonText}
        ></GoogleLogin>
      )}
    </div>
  );
};

GoogleAdsButton.defaultProps = {
  buttonText: "Button",
  buttonColor: "Button"
};

GoogleAdsButton.propTypes = {
  buttonText: PropTypes.string,
  buttonColor: PropTypes.string
};

export default GoogleAdsButton;
