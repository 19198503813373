import React, { useState, useEffect } from "react";
import ServiceBell from "@servicebell/widget";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { trace } from "firebase/performance";
import Logo from "../components/logo";
import FeatureCard1 from "../components/feature-card1";
import FeatureCard4 from "../components/feature-card4";
import "./audit-results.css";
import Spinner from "../components/spinner";

const AuditResults = (props) => {
  const { client, perf, clientId } = props;

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

  const [brandName, setBrandName] = useState("");
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [accountRatingTitle, setAccountRatingTitle] = useState("");
  const [accountRatingSubtitle, setAccountRatingSubtitle] = useState("");
  const [logo, setLogo] = useState(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  ServiceBell.init(props.serviceBellKey);

  const uid = queryParams.get("uid");
  const delay = () => new Promise((resolve) => setTimeout(resolve, 3000));

  useEffect(() => {
    let retryCounter = 0;
    async function getClientProperties() {
      const clientProperties = await client.getEntry(clientId);
      console.log(clientProperties);
      setBrandName(clientProperties.fields.brandName);
      setTitle(clientProperties.fields.auditResultsTitle);
      setSubtitle(clientProperties.fields.auditResultsSubtitle);
      setAccountRatingTitle(clientProperties.fields.accountRatingTitle);
      setAccountRatingSubtitle(clientProperties.fields.accountRatingSubtitle);
      setLogo(clientProperties.fields.logo.fields.file.url);
    }

    getClientProperties();

    const getImprovementsTrace = trace(perf, "AUDIT_RESULTS_GET_IMPROVEMENTS");
    let hasTraceStarted = false;

    async function getData() {
      setLoading(true);
      try {
        if (!hasTraceStarted) {
          getImprovementsTrace.start();
          hasTraceStarted = true;
        }

        const improvementsResponse = await fetch(
          encodeURI(
            `https://googleadslinking.campaignhero.app/api/improvements?uid=${uid}`
          ),
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json"
            }
          }
        );

        if (!improvementsResponse.ok) {
          Sentry.captureException(
            `${improvementsResponse.status} - ${improvementsResponse.statusText}`
          );
          throw new Error(
            `This is an HTTP error: The status is ${improvementsResponse.status}`
          );
        }

        const results = await improvementsResponse.json();
        if (results.improvements.length === 0) {
          await delay();
          return getData();
        }
        Sentry.captureMessage(
          `Successfully returned UID: ${uid} Improvements response with length: ${results.improvements.length}`
        );
        if (hasTraceStarted) getImprovementsTrace.stop();
        setData(results);
        console.log(results);
        setError(null);
        setLoading(false);
      } catch (err) {
        console.log(err);
        if (hasTraceStarted) getImprovementsTrace.stop();
        setError(err.message);
        Sentry.captureException(err);
        setData(null);
        setLoading(false);
      }
    }
    getData();
  }, [uid, client, perf, clientId]);

  return (
    <div className="audit-results-container">
      <Helmet>
        <title>Audit Results - CampaignHero Audit Results</title>
        <meta
          property="og:title"
          content="Audit Results - CampaignHero Audit Results"
        />
      </Helmet>
      <div data-role="Header" className="audit-results-header-container">
        <header className="audit-results-header">
          <Logo image_src={logo}></Logo>
        </header>
        <div data-type="MobileMenu" className="audit-results-mobile-menu">
          <div className="audit-results-top">
            <div className="audit-results-logo">
              <img
                alt="image"
                src="/playground_assets/logotype-dark.svg"
                className="audit-results-image"
              />
            </div>
            <div
              data-type="CloseMobileMenu"
              className="audit-results-close-menu"
            >
              <svg viewBox="0 0 1024 1024" className="audit-results-icon">
                <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
              </svg>
            </div>
          </div>
          <div className="audit-results-mid">
            <span className="audit-results-text">About</span>
            <span className="audit-results-text01">Products</span>
            <span className="audit-results-text02">Pricing</span>
            <span className="audit-results-text03">Blog</span>
            <span className="audit-results-text04">Jobs</span>
            <span className="audit-results-text05">More</span>
          </div>
          <div className="audit-results-bot">
            <div className="audit-results-container01">
              <a
                href="https://instagram.com"
                target="_blank"
                rel="noreferrer noopener"
                className="audit-results-link"
              >
                <div className="audit-results-container02">
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="audit-results-icon02"
                  >
                    <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
                  </svg>
                </div>
              </a>
              <a
                href="https://dribbble.com"
                target="_blank"
                rel="noreferrer noopener"
                className="audit-results-link1"
              >
                <div className="audit-results-container03">
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="audit-results-icon04"
                  >
                    <path d="M585.143 857.143c-5.714-33.143-27.429-147.429-80-284.571-0.571 0-1.714 0.571-2.286 0.571 0 0-222.286 77.714-294.286 234.286-3.429-2.857-8.571-6.286-8.571-6.286 65.143 53.143 148 85.714 238.857 85.714 52 0 101.143-10.857 146.286-29.714zM479.429 510.286c-9.143-21.143-19.429-42.286-30.286-63.429-193.143 57.714-378.286 53.143-384.571 53.143-0.571 4-0.571 8-0.571 12 0 96 36.571 184 96 250.286v0c102.286-182.286 304.571-247.429 304.571-247.429 5.143-1.714 10.286-2.857 14.857-4.571zM418.286 389.143c-65.143-115.429-134.286-209.143-139.429-216-104.571 49.143-182.286 145.714-206.857 261.714 9.714 0 166.286 1.714 346.286-45.714zM809.143 571.429c-8-2.286-112.571-35.429-233.714-16.571 49.143 135.429 69.143 245.714 73.143 268 84-56.571 143.429-146.857 160.571-251.429zM349.143 148c-0.571 0-0.571 0-1.143 0.571 0 0 0.571-0.571 1.143-0.571zM686.286 230.857c-65.714-58.286-152.571-93.714-247.429-93.714-30.286 0-60 4-88.571 10.857 5.714 7.429 76.571 100.571 140.571 218.286 141.143-52.571 194.286-133.714 195.429-135.429zM813.714 508c-1.143-88.571-32.571-170.286-85.143-234.286-1.143 1.143-61.143 88-209.143 148.571 8.571 17.714 17.143 36 25.143 54.286 2.857 6.286 5.143 13.143 8 19.429 129.143-16.571 256.571 11.429 261.143 12zM877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"></path>
                  </svg>
                </div>
              </a>
              <a
                href="https://twitter.com"
                target="_blank"
                rel="noreferrer noopener"
                className="audit-results-link2"
              >
                <div className="audit-results-container04">
                  <svg
                    viewBox="0 0 950.8571428571428 1024"
                    className="audit-results-icon06"
                  >
                    <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
                  </svg>
                </div>
              </a>
              <a
                href="https://youtube.com"
                target="_blank"
                rel="noreferrer noopener"
                className="audit-results-link3"
              >
                <div className="audit-results-container05">
                  <svg viewBox="0 0 1024 1024" className="audit-results-icon08">
                    <path d="M406.286 644.571l276.571-142.857-276.571-144.571v287.429zM512 152c215.429 0 358.286 10.286 358.286 10.286 20 2.286 64 2.286 102.857 43.429 0 0 31.429 30.857 40.571 101.714 10.857 82.857 10.286 165.714 10.286 165.714v77.714s0.571 82.857-10.286 165.714c-9.143 70.286-40.571 101.714-40.571 101.714-38.857 40.571-82.857 40.571-102.857 42.857 0 0-142.857 10.857-358.286 10.857v0c-266.286-2.286-348-10.286-348-10.286-22.857-4-74.286-2.857-113.143-43.429 0 0-31.429-31.429-40.571-101.714-10.857-82.857-10.286-165.714-10.286-165.714v-77.714s-0.571-82.857 10.286-165.714c9.143-70.857 40.571-101.714 40.571-101.714 38.857-41.143 82.857-41.143 102.857-43.429 0 0 142.857-10.286 358.286-10.286v0z"></path>
                  </svg>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="audit-results-banner">
        <h1 className="audit-results-text06">{title}</h1>
        <span className="audit-results-text07">
          <span>
            <span>{subtitle}</span>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: " "
                }}
              />
            </span>
          </span>
        </span>
        <div className="audit-results-btn-group">
          <button className="audit-results-button button">Get Started</button>
          <button className="audit-results-button1 button">Learn More</button>
        </div>
      </div>
      <div className="audit-results-features">
        {loading && !error && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Spinner /> <br /> <p>Loading Improvements...</p>
          </div>
        )}
        {!loading && error && (
          <div>{`There was a problem fetching the Improvements!`}</div>
        )}
        {data && !error && data.improvements.length > 0 && (
          <div className="audit-results-container06">
            <FeatureCard1
              heading={accountRatingTitle}
              text={accountRatingSubtitle}
              accountRating={data.accountRating}
              accountRatingDescriptor={data.AccountRatingDescriptor}
            ></FeatureCard1>
          </div>
        )}
        <div className="audit-results-container07">
          <div className="audit-results-container08">
            {data &&
              !error &&
              data.improvements.length > 0 &&
              data.improvements.map((item) => {
                return (
                  <FeatureCard4
                    rootClassName="rootClassName1"
                    image_src={item.IconUrl}
                    title={item.title}
                    description={item.shortDescription}
                    improvementData={item.ImprovementData}
                  ></FeatureCard4>
                );
              })}
          </div>
        </div>
      </div>
      <div className="audit-results-footer">
        <footer className="audit-results-container09">
          <img
            width="80px"
            alt="image"
            src={logo}
            className="audit-results-image1"
          />
          <div className="audit-results-container10">
            <span className="audit-results-text11 body2">
              © 2020 {brandName}. All rights reserved
            </span>
            <div className="audit-results-container11">
              <a
                href="https://instagram.com"
                target="_blank"
                rel="noreferrer noopener"
                className="audit-results-link4"
              >
                <div className="audit-results-container12">
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="audit-results-icon10"
                  >
                    <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
                  </svg>
                </div>
              </a>
              <a
                href="https://dribbble.com"
                target="_blank"
                rel="noreferrer noopener"
                className="audit-results-link5"
              >
                <div className="audit-results-container13">
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="audit-results-icon12"
                  >
                    <path d="M585.143 857.143c-5.714-33.143-27.429-147.429-80-284.571-0.571 0-1.714 0.571-2.286 0.571 0 0-222.286 77.714-294.286 234.286-3.429-2.857-8.571-6.286-8.571-6.286 65.143 53.143 148 85.714 238.857 85.714 52 0 101.143-10.857 146.286-29.714zM479.429 510.286c-9.143-21.143-19.429-42.286-30.286-63.429-193.143 57.714-378.286 53.143-384.571 53.143-0.571 4-0.571 8-0.571 12 0 96 36.571 184 96 250.286v0c102.286-182.286 304.571-247.429 304.571-247.429 5.143-1.714 10.286-2.857 14.857-4.571zM418.286 389.143c-65.143-115.429-134.286-209.143-139.429-216-104.571 49.143-182.286 145.714-206.857 261.714 9.714 0 166.286 1.714 346.286-45.714zM809.143 571.429c-8-2.286-112.571-35.429-233.714-16.571 49.143 135.429 69.143 245.714 73.143 268 84-56.571 143.429-146.857 160.571-251.429zM349.143 148c-0.571 0-0.571 0-1.143 0.571 0 0 0.571-0.571 1.143-0.571zM686.286 230.857c-65.714-58.286-152.571-93.714-247.429-93.714-30.286 0-60 4-88.571 10.857 5.714 7.429 76.571 100.571 140.571 218.286 141.143-52.571 194.286-133.714 195.429-135.429zM813.714 508c-1.143-88.571-32.571-170.286-85.143-234.286-1.143 1.143-61.143 88-209.143 148.571 8.571 17.714 17.143 36 25.143 54.286 2.857 6.286 5.143 13.143 8 19.429 129.143-16.571 256.571 11.429 261.143 12zM877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"></path>
                  </svg>
                </div>
              </a>
              <a
                href="https://twitter.com"
                target="_blank"
                rel="noreferrer noopener"
                className="audit-results-link6"
              >
                <div className="audit-results-container14">
                  <svg
                    viewBox="0 0 950.8571428571428 1024"
                    className="audit-results-icon14"
                  >
                    <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
                  </svg>
                </div>
              </a>
              <a
                href="https://youtube.com"
                target="_blank"
                rel="noreferrer noopener"
                className="audit-results-link7"
              >
                <div className="audit-results-container15">
                  <svg viewBox="0 0 1024 1024" className="audit-results-icon16">
                    <path d="M406.286 644.571l276.571-142.857-276.571-144.571v287.429zM512 152c215.429 0 358.286 10.286 358.286 10.286 20 2.286 64 2.286 102.857 43.429 0 0 31.429 30.857 40.571 101.714 10.857 82.857 10.286 165.714 10.286 165.714v77.714s0.571 82.857-10.286 165.714c-9.143 70.286-40.571 101.714-40.571 101.714-38.857 40.571-82.857 40.571-102.857 42.857 0 0-142.857 10.857-358.286 10.857v0c-266.286-2.286-348-10.286-348-10.286-22.857-4-74.286-2.857-113.143-43.429 0 0-31.429-31.429-40.571-101.714-10.857-82.857-10.286-165.714-10.286-165.714v-77.714s-0.571-82.857 10.286-165.714c9.143-70.857 40.571-101.714 40.571-101.714 38.857-41.143 82.857-41.143 102.857-43.429 0 0 142.857-10.286 358.286-10.286v0z"></path>
                  </svg>
                </div>
              </a>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default AuditResults;
