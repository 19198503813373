import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { initializeApp } from "firebase/app";
import { getPerformance } from "firebase/performance";
import TagManager from "gtm-for-react";

import "./style.css";
import Home from "./views/home";
import AuditResults from "./views/audit-results";

const contentful = require("contentful");

const gtmId = process.env.REACT_APP_GTM_ID;
const clientId = process.env.REACT_APP_CLIENT_ID;
const contentfulSpace = process.env.REACT_APP_CONTENTFUL_SPACE;
const contentfulAccessToken = process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN;
const firebaseConfig = process.env.REACT_APP_FIREBASE_CONFIG;
const sentryDsn = process.env.REACT_APP_SENTRY_DSN;
const serviceBellKey = process.env.REACT_APP_SERVICEBELL_KEY;

TagManager.initialize({
  gtmId: gtmId
});

const client = contentful.createClient({
  space: contentfulSpace,
  accessToken: contentfulAccessToken
});

const firebaseConfigJson = JSON.parse(firebaseConfig);
const app = initializeApp(firebaseConfigJson);
const perf = getPerformance(app);

Sentry.init({
  dsn: sentryDsn,
  tracesSampleRate: 1.0
});

const App = () => {
  return (
    <Router>
      <div>
        <Route
          exact
          render={() => (
            <Home client={client} perf={perf} clientId={clientId} />
          )}
          path="/"
        />
        <Route
          render={() => (
            <AuditResults
              client={client}
              perf={perf}
              clientId={clientId}
              serviceBellKey={serviceBellKey}
            />
          )}
          path="/audit-results"
        />
      </div>
    </Router>
  );
};

ReactDOM.render(<App />, document.getElementById("app"));
